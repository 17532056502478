<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="400"
    v-model="dialog"
    @click:outside="modalAction"
  >
    <v-container class="pt-1 background rounded-lg">
      <v-row class="ma-0"
        ><v-col cols="12" class="pa-0"
          ><v-icon
            class="mt-2 float-right"
            color="secondary"
            @click="modalAction"
            >mdi-close</v-icon
          ></v-col
        ></v-row
      >
      <v-row
        ><v-col
          class="pt-0 text-center primary--text font-weight-bold"
          cols="12 "
          >{{ $t("draft.delete_text") }}</v-col
        ></v-row
      >
      <v-row justify="center" class="mb-1">
        <v-col cols="12" md="7" class="d-flex justify-center">
          <v-btn
            large
            color="red"
            class="white--text rounded-lg"
            @click="$emit('confirm')"
            >{{ $t("draft.confirm") }}</v-btn
          >
        </v-col>
        <v-col cols="12" md="5" class="d-flex justify-center"
          ><v-btn
            @click="modalAction"
            class="rounded-lg"
            large
            color="primary"
            outlined
            >{{ $t("draft.cancel") }}</v-btn
          ></v-col
        >
      </v-row>
    </v-container>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      loadingButton: false
    };
  },

  methods: {
    modalAction() {
      this.$emit("closeModal");
    }
  }
};
</script>
<style scoped>
.background {
  background-color: white;
  background-repeat: no-repeat;
  background-position: bottom;
}
.theme--light.v-divider {
  border-color: var(--v-secondary-base) !important;
}
</style>
