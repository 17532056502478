<template>
  <v-container v-if="mounted">
    <layout :back="true" />
    <custom-modal
      :openModal="closeSelection"
      :confirm="true"
      @closeModal="toggleCloseSelectionModal"
      @confirm="closeExpertOffer(offer.id)"
      :text="'close_selection_title'"
      :title="'close_selection_text'"
    />
    <close-draft-modal
      :dialog="closeDraftConfirm"
      @closeModal="toggleCloseDraftModal"
      @confirm="deleteDraft"
    />
    <video-player
      v-if="offer.video"
      @closeVideoPlayer="toggleVideoPlayer"
      :showVideoPlayer.sync="videoPlayer"
      :src="offer.video"
    />
    <v-row justify-sm="center">
      <v-col cols="12" md="5" sm="8">
        <v-list-item class="primary--text">
          <v-list-item-content>
            <v-list-item-title class="primary--text">{{
              offer.position.name
            }}</v-list-item-title>
            <v-list-item-subtitle
              class="text-body-2 font-weight-light secondary--text"
            >
              {{
                $t("pharmacy.name", { name: offer.pharmacy.commercial_name })
              }},
            </v-list-item-subtitle>
            <v-row class="text-body-2 font-weight-light secondary--text pa-0">
              <v-col cols="10"> {{ offer | formatAddress }}.</v-col>
              <v-col cols="1" v-if="offer.video">
                <div class="mt-n3">
                  <v-btn
                    class="pa-0 mb-1"
                    color="primary"
                    @click="toggleVideoPlayer"
                  >
                    <v-icon> mdi-play-circle </v-icon>
                  </v-btn>
                </div></v-col
              >
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <!-- <v-col cols="2" md="2" sm="2" class="text-end"
        ><v-icon class="mt-3">mdi-dots-vertical</v-icon></v-col
      > -->
    </v-row>
    <v-row class="ma-0 pa-0" justify-sm="center">
      <v-col cols="12" md="5" sm="8">
        <v-tabs fixed-tabs v-model="tab" class="v-slider-group">
          <v-tabs-slider color="primary--text"></v-tabs-slider>
          <v-tab class="text-caption ma-0 pa-0 mx-2">
            {{ $tc("offer.title", 1) }}
          </v-tab>
          <v-tab class="text-caption ma-0 pa-0 mx-2">
            {{ $tc("candidate.title", 2, { number: candidates.length }) }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab" class="mb-10">
      <v-tab-item>
        <v-row justify-sm="center">
          <v-col cols="12" md="5" sm="8" class="ma-0 pa-0 mt-3 primary--text">
            <v-list-item class="primary--text ml-2">
              <v-list-item-content>
                <v-col :cols="userType == 'expert' ? 6 : 8" class="ma-0 pa-0">
                  {{ $t("offer.detail") }}</v-col
                >
                <v-col
                  v-if="userType == 'expert'"
                  cols="6"
                  class="ma-0 pa-0 mb-1 text-right"
                >
                  <v-btn
                    x-small
                    rounded
                    color="error"
                    :disabled="offer.expert_done"
                    @click="closeSelection = true"
                    >{{ $t("offer.close_selection") }}</v-btn
                  ></v-col
                >
                <v-col
                  v-else-if="offer.draft"
                  cols="4"
                  class="pr-1 ma-0 pa-0 text-right"
                >
                  <v-btn
                    x-small
                    rounded
                    outlined
                    style="border: none"
                    :to="{
                      name: 'offers-edit',
                      params: {
                        offer_id: offer.id,
                      },
                    }"
                    ><v-icon size="20">mdi-pencil</v-icon></v-btn
                  ></v-col
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <!-- <v-col cols="2" md="2" sm="2" class="text-end"
            ><v-icon class="mt-3" color="primary"
              >mdi-dots-vertical</v-icon
            ></v-col
          > -->
        </v-row>
        <v-row class="ma-0" justify-sm="center">
          <v-col cols="12" md="5" sm="8" class="pa-0 mt-1">
            <v-divider class="mx-4"></v-divider>
          </v-col>
        </v-row>

        <v-row class="ma-0" justify-sm="center">
          <v-col cols="12" md="5" sm="8" class="ma-0 pa-0">
            <v-list-item
              class="text-subtitle-2 font-weight-light tertiary--text"
            >
              <v-list-item-content>
                <v-list-item-subtitle class="detailText mb-5 primary--text">
                  {{ $t("offer.validity") }}:
                  {{ offer.created | moment("DD/MM/YYYY") }} -
                  {{ offer.valid_until | moment("DD/MM/YYYY") }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="primary--text">
                  <span class="font-weight-bold">{{
                    $t("offer.pharmacy_title")
                  }}</span>
                  <span class="detailText"> {{ offer.position.name }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="primary--text"   v-if="offer.expertise && offer.expertise.name != null" >
                  <span class="font-weight-bold">{{
                    $t("offer.expertise")
                  }}</span>
                  <span class="detailText">{{
                    offer.expertise && offer.expertise.name
                  }}</span>
                </v-list-item-subtitle>
                <!-- <v-list-item-subtitle class="primary--text font-weight-bold">
                  • 22 horas semanales
                </v-list-item-subtitle> -->
                <v-list-item-subtitle class="primary--text">
                  <span class="font-weight-bold">{{
                    $t("offer.working_day")
                  }}</span>
                  <ul style="list-style-type: none">
                    <li
                      v-for="(elem, index) in offer.working_day"
                      :key="index"
                      class="primary--text"
                    >
                      <span class="detailText">
                        {{ "• " + elem.name + "." }}
                      </span>
                    </li>
                  </ul>
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="offer.working_hours != null"
                  class="primary--text font-weight-bold"
                >
                  <span class="font-weight-bold"
                    >{{ $t("offer.working_hours") }}:
                  </span>
                  <span class="detailText">{{ offer.working_hours }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-if="offer.working_hours_comment != null"
                  class="primary--text font-weight-bold"
                >
                  <span class="font-weight-bold"
                    >{{ $t("offer.working_hours_comment") }}:
                  </span>
                  <span class="detailText">{{
                    offer.working_hours_comment
                  }}</span>
                </v-list-item-subtitle>

                <v-list-item-subtitle class="primary--text font-weight-bold">
                  <span class="font-weight-bold"
                    >{{ $t("offer.contract") }}
                  </span>
                  <span class="detailText">{{ offer.contract_type.name }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="primary--text font-weight-bold">
                  <span class="font-weight-bold"
                    >{{ $t("offer.remuneration_model") }}:
                  </span>
                  <span class="detailText">{{ offer.remuneration_model }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="primary--text font-weight-bold">
                  <span class="font-weight-bold"
                    >{{ $t("offer.incentives") }}:
                  </span>
                  <span v-if="offer.incentives" class="detailText">{{
                    $t("field.yes")
                  }}</span>
                  <span v-else class="detailText">{{ $t("field.no") }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="primary--text font-weight-bold">
                  <span class="font-weight-bold"
                    >{{ $t("offer.incorporation_date") }}:
                  </span>
                  <span class="detailText">{{
                    offer.incorporation_date | moment("DD-MM-YYYY")
                  }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="primary--text"
                  ><span class="font-weight-bold">{{
                    "• " + $t("offer.required_language")
                  }}:</span>
                  <ul style="list-style-type: none">
                    <li
                      v-for="(language, index) in offer.offer_languages.filter(
                        (elm) => elm.required == true
                      )"
                      :key="'requiered_' + index"
                      class="primary--text"
                    >
                      <span class="detailText">
                        {{ "• " + language.language + "." }}
                      </span>
                    </li>
                  </ul>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="primary--text" v-if="offer.offer_languages">
                  <span class="font-weight-bold">{{
                    "• " + $t("offer.opcional_language")
                  }}</span>
                  <ul style="list-style-type: none">
                    <li
                      v-for="(language, index) in offer.offer_languages.filter(
                        (elm) => elm.required == false
                      )"
                      :key="'optional_' + index"
                      class="primary--text"
                    >
                      <span class="detailText">
                        {{ "• " + language.language + "." }}
                      </span>
                    </li>
                  </ul>
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  class="primary--text font-weight-bold mt-3"
                >
                  <span class="font-weight-bold"
                    >{{ "• " + $t("offer.pharmacy_owner_name") }}
                  </span>
                  <span v-if="offer.pharmacy.pharmacy_owner" class="detailText">{{
                    offer.pharmacy.pharmacy_owner.full_name
                  }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="primary--text font-weight-bold">
                  <span class="font-weight-bold"
                    >{{ "• " + $t("field.phone_number") }}
                  </span>
                  <span v-if="offer.pharmacy.pharmacy_owner" class="detailText">{{
                    offer.pharmacy.pharmacy_owner.phone_number
                  }}</span>
                </v-list-item-subtitle>

                <v-list-item-subtitle
                  class="primary--text font-weight-bold"
                  v-for="(item, index) in offer.pharmacy.contacts"
                  :key="'contact_' + index"
                >
                  <div class="mt-2">
                    <span class="font-weight-bold"
                      >{{ "• " +$t("field.contact_name") }}
                    </span>
                    <span class="detailText">{{ item.full_name }}</span>
                  </div>
                  <div>
                    <span class="font-weight-bold"
                      >{{"• " + $t("field.phone_number") }}
                    </span>
                    <span class="detailText">{{ item.phone_number }}</span>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row
          class="ma-0 mb-5 mt-16"
          justify="center"
          v-if="offer.draft && userType != 'expert'"
        >
          <v-col
            cols="6"
            md="2"
            sm="3"
            class="ml-n4 pa-0 d-flex justify-center"
          >
            <v-btn
              style="border: none"
              outlined
              color="primary"
              large
              class="error--text font-weight-semibold"
              @click="toggleCloseDraftModal"
              >{{ $t("offer.delete") }}</v-btn
            >
          </v-col>
          <v-col
            cols="6"
            md="2"
            sm="3"
            class="pa-0 d-flex justify-center"
            v-if="offer.draft && userType != 'expert'"
          >
            <v-btn
              class="pa-0 px-2 font-weight-semibold"
              color="secondary"
              large
              @click="showConfirmPay = true"
              >{{ $t("offer.offer_pay") }}</v-btn
            >
          </v-col>
           <v-col
            cols="12"
            md="12"
            sm="12"
            class="pa-0 mt-3 d-flex justify-center"
            v-if="offer.draft && userType != 'expert'"
          >
             <v-btn
              outlined
              color="primary"
              large
              class="primary--text font-weight-semibold px-12"
              @click="goToMenu"
              >{{ $t("offer.menu") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row justify-sm="center">
          <v-col cols="12" md="5" sm="8" class="ma-0">
            <v-col cols="12" sm="12" md="12" class="ma-0 pa-0 primary--text">
              <v-text-field
                :label="
                  $tc('candidate.title', 2, { number: candidates.length })
                "
                append-icon="mdi-magnify"
                hide-details
                class="mx-2 pa-0 mb-2"
              >
              </v-text-field>
            </v-col>
          </v-col>
        </v-row>
        <v-row
          class="ma-0"
          justify-sm="center"
          v-for="(candidate, index) in candidates"
          :key="'candidate_' + index"
        >
          <v-col cols="12" md="5" sm="8" class="pa-0 mt-1">
            <v-card
              class="mb-2"
              :to="{
                name: 'pharmacy-candidate-detail',
                params: {
                  candidate_id: candidate.id,
                },
                query: {
                  offer_id: offer.id,
                },
              }"
              ><v-list-item>
                <v-list-item-avatar tile size="50">
                  <v-img
                    alt="user avatar"
                    class="shrink"
                    contain
                    :src="candidate.avatar"
                    transition="scale-transition"
                /></v-list-item-avatar>
                <v-col cols="7" md="7" sm="7" class="pa-0 mt-1">
                  <div class="text-subtitle-1 primary--text">
                    {{ candidate.full_name }}
                  </div>
                  <div class="text-caption">
                    <v-icon size="17" color="secondary">mdi-map-marker</v-icon
                    >{{
                      candidate.address.locality.state.country.name +
                      "," +
                      candidate.address.locality.name
                    }}
                  </div>
                </v-col>
                <v-col cols="3" md="3" sm="3" class="pa-0 mt-1">
                  <div class="text-end">
                    <v-chip
                      small
                      :color="statusColor(candidate)"
                      text-color="white"
                    >
                      {{ showStatus(candidate) }}
                    </v-chip>
                  </div>
                </v-col>
              </v-list-item></v-card
            >
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <offer-confirm-pay
      @confirmPay="redirectToCheckout"
      @closeModalPay="closeModalPay"
      :isDraft="true"
      :openModal.sync="showConfirmPay"
    ></offer-confirm-pay>
  </v-container>
</template>
<script>
// Layout component
import layout from "@/components/layouts/Profile.vue";
import OffersService from "@/services/OffersService.js";
import CustomModal from "@/components/modals/CustomModal.vue";
import CloseDraftModal from "@/components/modals/CloseDraftModal.vue";
import VideoPlayer from "@/components/modals/VideoPlayer.vue";
import OfferConfirmPay from "@/components/modals/OfferConfirmPay.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    layout,
    VideoPlayer,
    CustomModal,
    CloseDraftModal,
    OfferConfirmPay,
  },
  data() {
    return {
      showConfirmPay: false,
      closeDraftConfirm: false,
      items: [
        this.$i18n.tc("offer.title", 1),
        this.$i18n.tc("candidate.title", 2, { number: "jo" }),
      ],
      colors: ["grey", "#f39f18", "success", "error"],
      tab: "",
      closeSelection: false,
      videoPlayer: false,
      value: null,
      mounted: false,
      stripeAPIToken: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    };
  },
  async created() {

    this.enableLoading();
    await this.getOfferById(this.$route.params.offer_id);
    this.disableLoading();
    this.mounted = true;
    this.getCandidates({
      offer_id: this.$route.params.offer_id,
      pharmacy_status:
        ["pharmacy", "pharmacygroup"].includes(this.userType) ||
        this.userType == "alquimia"
          ? false
          : null,
      expert_status: this.userType == "expert" ? false : null,
    });
    this.getStatusChoices(
      ["alquimia", "pharmacygroup"].includes(this.userType)
        ? "pharmacy"
        : this.userType
    );
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "https://js.stripe.com/v3/");
    document.head.appendChild(recaptchaScript);
  },
  computed: {
    ...mapGetters({ offer: "offer/offer" }),
    ...mapGetters({ candidates: "match/candidates" }),
    ...mapGetters({ userType: "user/type" }),
    ...mapGetters({ pharmacyStatus: "core/pharmacyStatusChoices" }),
  },
  methods: {
    ...mapActions({ closeOffer: "expert/closeOffer" }),
    ...mapActions({ offerDeleteDraft: "offer/deleteDraft" }),
    ...mapActions({ getOfferById: "offer/getOfferById" }),
    ...mapActions({ getCandidates: "match/getCandidates" }),
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    ...mapActions({
      getStatusChoices: "core/getStatusChoices",
    }),
    goToMenu(){
      this.$router.push({ name: "offers" });
    },
    async redirectToCheckout(confirm) {
      // eslint-disable-next-line no-undef
      var stripe = Stripe(this.stripeAPIToken);
      OffersService.pay({
        stripe: stripe,
        offer_id: this.offer.id,
        product: confirm,
      }).catch((err) => alert(err));
    },
    showStatus(candidate) {
      let status =
        this.userType == "expert"
          ? candidate.expert_status
          : candidate.pharmacy_status;
      return this.pharmacyStatus.filter((elm) => elm[0] == status)[0][1];
    },
    statusColor(candidate) {
      let status =
        this.userType == "expert"
          ? candidate.expert_status
          : candidate.pharmacy_status;
      return this.colors[
        this.pharmacyStatus.findIndex((elm) => elm[0] == status)
      ];
    },
    toggleCloseSelectionModal() {
      this.closeSelection = !this.closeSelection;
    },
    closeModalPay() {
      this.showConfirmPay = !this.showConfirmPay;
    },
    toggleCloseDraftModal() {
      this.closeDraftConfirm = !this.closeDraftConfirm;
    },
    toggleVideoPlayer() {
      this.videoPlayer = !this.videoPlayer;
    },
    async closeExpertOffer(offerId) {
      try {
        await this.closeOffer(offerId);
        this.$notify({
          title: this.$i18n.t("notification.close_selection"),
          type: "success",
        });
        this.toggleCloseSelectionModal();
      } catch {
        this.$notify({
          title: this.$i18n.t("notification.close_selection_error"),
          type: "error",
        });
        this.toggleCloseSelectionModal();
      }
    },
    async deleteDraft() {
      this.toggleCloseDraftModal();
      let response = await this.offerDeleteDraft(this.offer.id);
      if (response.status === 204) {
        this.$notify({
          title: this.$i18n.t("notification.delete_draft"),
          type: "success",
        });
        this.$router.push({ name: "offers" });
      } else {
        this.$notify({
          title: this.$i18n.t("notification.delete_draft_error"),
          type: "error",
        });
      }
    },
  },
};
</script>
<style scoped>
.v-slider-group {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}
.v-divider {
  border-color: #718f94 !important;
}
.theme--light.v-tabs-items {
  background-color: transparent;
}
.detailText {
  font-size: 13px;
  margin-bottom: 3px;
  opacity: 0.6;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 30px;
}
</style>
